<template>
  <v-container fluid>
    <base-material-card
      color="primary"
      icon="mdi-file-marker-outline"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">Exportações de BDGD</div>
      </template>

      <v-btn
        v-if="accessReleased('EXPORTAR_BDGD_ADICIONAR')"
        id="btn-nova-exportacao"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push('add')"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Nova Exportação
      </v-btn>

      <v-text-field
        id="exportacoes-historico-search"
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <general-progress-bars
        class="mt-11"
        :items="downloadProgressData"
        prefixText="Baixando BDGD"
      />

      <v-divider class="mt-10" />

      <v-data-table
        class="exportacoes-bdgd-table"
        :headers="headers"
        :items="items"
        :search.sync="search"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhuma exportação de BDGD disponível"
      >
        <template v-slot:[`item.entidades`]="{ item }">
          <div
            id="exportacoes-column-entity"
            class="clickable-item"
            @click="openDialogTabelas(item.entidades)"
          >
            {{ item.entidades | handleTabelasColumnValue }}
          </div>
        </template>
        <template v-slot:[`item.numero_linhas`]="{ item }">
          <div id="exportacoes-numero-linhas">
            {{ item.numero_linhas | parseNumberToIntegerBR }}
          </div>
        </template>
        <template v-slot:[`item.data_registro`]="{ item }">
          {{ item.data_registro | formatToMonth }} v{{ item.versao }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <general-status :status="item.status" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            :class="item.status != 'SUCESSO' ? 'cursor-block' : ''"
            id="action-download-bdgd"
            class="px-1"
            min-width="0"
            fab
            icon
            x-small
            @click="baixarZip(item)"
            :loading="
              downloadProgressData[item.id] !== undefined ? true : false
            "
            :disabled="item.status != 'SUCESSO'"
          >
            <v-icon small> mdi-download </v-icon>
          </v-btn>
          <v-btn
            id="exportacoes-log-criacao"
            min-width="0"
            class="px-1"
            fab
            icon
            x-small
            @click="
              (dialogCreationLog = true),
                (dialogCreationLogData = {
                  username: item.usuario,
                  created: item.created
                })
            "
          >
            <v-icon medium> mdi-table-search </v-icon>
          </v-btn>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable')"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar as exportações de BDGD</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <v-dialog
      v-model="dialogTabelas"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>Entidades</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          {{ getTabelasConcat }}
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogTabelas = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import ExportacoesBdgdsService from '@/services/ExportacoesBdgdsService';
import powerupsActionsMixins from '@/mixins/powerupsActionsMixins.js';
import profilePermissionsMixin from '@/mixins/profilePermissionsMixin';

export default {
  mixins: [powerupsActionsMixins, profilePermissionsMixin],

  components: {
    DialogCreationLog: () => import('@/components/general/DialogCreationLog'),
    GeneralStatus: () => import('@/components/general/GeneralStatus.vue'),
    GeneralProgressBars: () =>
      import('@/components/general/GeneralProgressBars.vue')
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    powerupService: ExportacoesBdgdsService,
    powerupEntityName: 'Exportação de BDGD',
    dialogTabelas: false,
    dialogTabelasData: [],
    search: '',
    headers: [
      {
        text: '#',
        value: 'id'
      },
      {
        text: 'Mês dos dados',
        value: 'data_registro'
      },
      {
        text: 'Entidades',
        value: 'entidades'
      },
      {
        text: 'Nº de linhas',
        value: 'numero_linhas'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        class: 'pl-4'
      }
    ]
  }),

  filters: {
    handleTabelasColumnValue(arrayTabelas) {
      let formattedValue = arrayTabelas[0].toUpperCase();
      let lengthArray = arrayTabelas.length;
      formattedValue =
        lengthArray > 1
          ? `${formattedValue} + ${lengthArray - 1}`
          : formattedValue;
      return formattedValue;
    }
  },

  methods: {
    openDialogTabelas(arrNameTables) {
      this.dialogTabelasData = arrNameTables;
      this.dialogTabelas = true;
    },

    baixarZip(exportacao) {
      const { id, data_registro, versao } = exportacao;
      const timestamp = dayjs(new Date()).format('YYYYMMDDHHmm');
      const nomeArquivo = `bdgd-mes-${data_registro}-versao-${versao}-${timestamp}.zip`;

      this.$set(this.downloadProgressData, id, { id });
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 2000
      });

      ExportacoesBdgdsService.baixarZip(id, {
        timeout: 60 * 60 * 1000,
        onDownloadProgress: (progressEvent) => {
          let progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          let isComputable = progressEvent.lengthComputable;
          this.$set(this.downloadProgressData, id, {
            progress,
            isComputable,
            id
          });
        }
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', nomeArquivo);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$toast.error('Erro ao baixar o arquivo zip com a BDGD.', '', {
            position: 'topRight'
          });
          console.error('Erro: ', error);
        })
        .finally(() => {
          this.$delete(this.downloadProgressData, id);
        });
    }
  },

  computed: {
    getTabelasConcat() {
      return this.dialogTabelasData.join(', ').toUpperCase();
    }
  }
};
</script>
