var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-file-marker-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Exportações de BDGD")])]},proxy:true}])},[(_vm.accessReleased('EXPORTAR_BDGD_ADICIONAR'))?_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-nova-exportacao","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push('add')}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova Exportação ")],1):_vm._e(),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"exportacoes-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('general-progress-bars',{staticClass:"mt-11",attrs:{"items":_vm.downloadProgressData,"prefixText":"Baixando BDGD"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"exportacoes-bdgd-table",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma exportação de BDGD disponível"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.entidades",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clickable-item",attrs:{"id":"exportacoes-column-entity"},on:{"click":function($event){return _vm.openDialogTabelas(item.entidades)}}},[_vm._v(" "+_vm._s(_vm._f("handleTabelasColumnValue")(item.entidades))+" ")])]}},{key:"item.numero_linhas",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"id":"exportacoes-numero-linhas"}},[_vm._v(" "+_vm._s(_vm._f("parseNumberToIntegerBR")(item.numero_linhas))+" ")])]}},{key:"item.data_registro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatToMonth")(item.data_registro))+" v"+_vm._s(item.versao)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('general-status',{attrs:{"status":item.status}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1",class:item.status != 'SUCESSO' ? 'cursor-block' : '',attrs:{"id":"action-download-bdgd","min-width":"0","fab":"","icon":"","x-small":"","loading":_vm.downloadProgressData[item.id] !== undefined ? true : false,"disabled":item.status != 'SUCESSO'},on:{"click":function($event){return _vm.baixarZip(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download ")])],1),_c('v-btn',{staticClass:"px-1",attrs:{"id":"exportacoes-log-criacao","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){(_vm.dialogCreationLog = true),
              (_vm.dialogCreationLogData = {
                username: item.usuario,
                created: item.created
              })}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v(" mdi-table-search ")])],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable')}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as exportações de BDGD")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialogTabelas),callback:function ($$v) {_vm.dialogTabelas=$$v},expression:"dialogTabelas"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-3",staticStyle:{"text-align":"left !important"}},[_c('h2',[_vm._v("Entidades")])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},[_vm._v(" "+_vm._s(_vm.getTabelasConcat)+" ")]),_c('v-divider'),_c('v-card-actions',{staticStyle:{"text-align":"right !important","display":"block !important"}},[_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialogTabelas = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }